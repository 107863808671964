<template>
    <div id="MemberUpgrade" class="wrap">
        <van-nav-bar
        z-index="10"
        :fixed="true"
        placeholder
        :title="$t('My.MemberUpgrade.MemberUpgrade')"
        left-arrow
        @click-left="onClickLeft"
        />
        <!-- <div class="header">
            <van-image  :src="require('@/assets/Img/myImg/vip_bg.png')" />
            <div class="content-item">
                <van-image round width="3.75rem" height="3.75rem"  :src="require('@/assets/Img/myImg/head.png')" />
                <div class="Text-item">
                    <span>{{$t("My.MemberUpgrade.Member")}}{{DataArr.Name}}</span>
                    <span>{{$t("My.MemberUpgrade.Orders")}}{{DataArr.Num}}</span>
                </div>
            </div>
        </div> -->
        <div class="content">
            <div class="content-item">
                <van-image round width="3.75rem" height="3.75rem"  :src="require('@/assets/Img/myImg/v1.png')" />
                <p style="color: #febb35;">{{$t("My.MemberUpgrade.Commission")}}</p>
            </div>
            <div class="content-item">
                <van-image round width="3.75rem" height="3.75rem"  :src="require('@/assets/Img/myImg/v2.png')" />
                <p style="color: #e65a69;">{{$t("My.MemberUpgrade.DedicatedOne")}}</p>
            </div>
            <div class="content-item">
                <van-image round width="3.75rem" height="3.75rem"  :src="require('@/assets/Img/myImg/v3.png')" />
                <p style="color: #33cdf8;">{{$t("My.MemberUpgrade.DedicatedTwo")}}</p>
            </div>
        </div>
        <!-- <div class="Text-Content">
            {{$t("My.MemberUpgrade.Swipe")}}
        </div> -->
        <div class="Bottom-Content">
            <p style="font-size: 16px;color: #a29d9d;margin-bottom: 0.5rem;">{{$t("My.MemberUpgrade.One.Silver")}}</p>
            <p style="color: #228aff;">{{$t("My.MemberUpgrade.One.Need")}}</p>
            <!-- <p>{{$t("My.MemberUpgrade.One.Withdrawal")}}</p> -->
            <p>{{$t("My.MemberUpgrade.One.quantity")}}</p>
            <p>{{$t("My.MemberUpgrade.One.rate")}}</p>
            <p>{{$t("My.MemberUpgrade.One.fee")}}</p>
            <!-- <p style="color:#a6a6a6;">{{$t("My.MemberUpgrade.One.Activated")}}</p> -->
        </div>
        <div class="Bottom-Content">
            <p style="font-size: 16px;color: #a29d9d;margin-bottom: 0.5rem;">{{$t("My.MemberUpgrade.Two.Silver")}}</p>
            <p style="color: #228aff;">{{$t("My.MemberUpgrade.Two.Need")}}</p>
            <p style="color: #228aff;">{{$t("My.MemberUpgrade.Two.People")}}</p>
            <!-- <p>{{$t("My.MemberUpgrade.Two.Withdrawal")}}</p> -->
            <p>{{$t("My.MemberUpgrade.Two.quantity")}}</p>
            <p>{{$t("My.MemberUpgrade.Two.rate")}}</p>
            <p>{{$t("My.MemberUpgrade.Two.fee")}}</p>
            <!-- <p>{{$t("My.MemberUpgrade.Two.Activated")}}</p> -->
            <!-- <p style="color:red;">{{$t("My.MemberUpgrade.Two.Invite")}}</p> -->
        </div>
        <div class="Bottom-Content">
            <p style="font-size: 16px;color: #a29d9d;margin-bottom: 0.5rem;">{{$t("My.MemberUpgrade.Three.Silver")}}</p>
            <p style="color: #228aff;">{{$t("My.MemberUpgrade.Three.Need")}}</p>
            <p style="color: #228aff;">{{$t("My.MemberUpgrade.Three.People")}}</p>
            <!-- <p>{{$t("My.MemberUpgrade.Three.Withdrawal")}}</p> -->
            <p>{{$t("My.MemberUpgrade.Three.quantity")}}</p>
            <p>{{$t("My.MemberUpgrade.Three.rate")}}</p>
            <p>{{$t("My.MemberUpgrade.Three.fee")}}</p>
            <!-- <p>{{$t("My.MemberUpgrade.Three.Activated")}}</p> -->
            <!-- <p style="color:red;">{{$t("My.MemberUpgrade.Three.Invite")}}</p> -->
        </div>
        <div class="Bottom-Content">
            <p style="font-size: 16px;color: #a29d9d;margin-bottom: 0.5rem;">{{$t("My.MemberUpgrade.Four.Silver")}}</p>
            <p style="color: #228aff;">{{$t("My.MemberUpgrade.Four.Need")}}</p>
            <p style="color: #228aff;">{{$t("My.MemberUpgrade.Four.People")}}</p>
            <!-- <p>{{$t("My.MemberUpgrade.Four.Withdrawal")}}</p> -->
            <p>{{$t("My.MemberUpgrade.Four.quantity")}}</p>
            <p>{{$t("My.MemberUpgrade.Four.rate")}}</p>
            <p>{{$t("My.MemberUpgrade.Four.fee")}}</p>
            <!-- <p>{{$t("My.MemberUpgrade.Four.Activated")}}</p> -->
            <!-- <p style="color:red;">{{$t("My.MemberUpgrade.Four.Invite")}}</p> -->
        </div>
    </div>
</template>

<script>
    import {
        Row,
        Col,
        Icon,
        Cell,
        GridItem,
        Grid,
        NavBar,
        ActionSheet,
        Button,
        Image 
    }from "vant";

    export default {
        name:"MemberUpgrade",
        components: {
            [Row.name]: Row,
            [Col.name]: Col,
            [Icon.name]: Icon,
            [Cell.name]: Cell,
            [GridItem.name]: GridItem,
            [Grid.name]: Grid,
            [NavBar.name]: NavBar,
            [ActionSheet.name]: ActionSheet,
            [Button.name]: Button,
            [Image.name]:Image
        },
        data(){
            return{
                DataArr:{
                    Name:"Silver member",
                    Num:"10",
                }
            }
        },
        methods:{
            // 返回上一个路由
            onClickLeft(){
                this.$router.go(-1);
            },
        
        },
        created() {
            window.scroll(0, 0);
        },
    }
</script>

<style lang="less">
    #MemberUpgrade{
        width: 100%;
        height: 100%;
        margin-bottom: 0.5rem;
        >.header{
            width: 100%;
            height: 9rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            background: -webkit-linear-gradient(left,#27a6fa,#8e71f5);
            position: relative;
            .content-item{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 1;
                bottom: 1.5rem;
                >.Text-item{
                    margin-left: 0.5rem;
                    display: flex;
                    flex-direction: column;
                    >span{
                        font-size: 0.875rem;
                        color: #fff;
                    }
                    >span:nth-child(1){
                        font-weight: 700;
                        margin-bottom: 0.5rem;
                    }
                }
            }
            >.van-image{
                width: 92%;
                height: 7rem;
                >.van-image__img{
                    border-radius: 0.5rem 0.5rem 0 0;
                }
            }
        }
        >.content{
            width: 92%;
            margin: 1rem auto 1rem;
            display: flex;
            flex-direction: row;
            >.content-item{
                width: 33.33%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                >p{
                    text-align: center;
                    width: 100%;
                    height: 1.5rem;
                    font-size: 0.875rem;
                }
            }
        }
        >.Text-Content{
            text-align: center;
            color: #d4d2d2;
            padding: 1em;
            font-size: 12px;
        }
        >.Bottom-Content{
            padding: 1rem 0;
            width: 92%;
            margin: 0 auto 1rem;
            border: 1px #ccc solid;
            border-radius: 0.5rem;
            >p{
                text-align: center;
                line-height: 1.3rem;
                font-size: 0.75rem;
                color: #000;
            }
        }
    }
</style>